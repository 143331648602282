import * as React from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import Ross1 from "../gifs/ross-1.gif"
import Ross2 from "../gifs/ross-2.gif"
import Ross3 from "../gifs/ross-3.gif"
import Ross4 from "../gifs/ross-4.gif"
import Ross5 from "../gifs/ross-5.gif"
import Ross6 from "../gifs/ross-6.gif"


const NotFoundPage = () => {

  const gifs = [Ross1,Ross2,Ross3,Ross4,Ross5,Ross6]

  const finalRossGif = gifs[Math.floor(Math.random()*gifs.length)]


  return (
    <Layout>
      <Seo title="404: Not found" />
      <section className="container error">
        <div className="container__inner error__inner">
          <img src={finalRossGif} className="error__image" alt="" />
          <h1 className="error__title">Oh no! Something went wrong.</h1>
          <div className="error__content"><p>The page you were looking for could not be found.<br />Please check the URL, or return to the <a href="/">homepage</a>.</p></div>
        </div>
      </section>
    </Layout>

  )
}

export default NotFoundPage
